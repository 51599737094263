import SiteHeader from "./Components/SiteHeader";
import SiteBody from "./Components/SiteBody";
import "antd/dist/antd.css";

function App() {
  return (
    <div
      style={{
        textAlign: "center",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        fontSize: "18",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "fixed",
          maxheight: 100,
          height: 100,
          width: "100%",
          zIndex: 3,
          backgroundColor: "white",
        }}
      >
        <SiteHeader />
      </div>
      <div
        style={{
          marginTop: 100,
        }}
      >
        <SiteBody />
      </div>
    </div>
  );
}

export default App;
