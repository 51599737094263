import React from "react";
import logo from "../Resources/PabhaLogo.png";

function SiteHeader(props) {
  return (
    <div
      style={{
        maxheight: 100,
        height: 100,
        textAlign: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          height: 100,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          padding: 20,
          overflow: "hidden",
        }}
      >
        <p
          style={{
            fontSize: "300%",
          }}
        >
          Prabhakara Jimmy Quek's Artwork Collection
        </p>
      </div>
      <div
        style={{
          height: 100,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          padding: 20,
          marginRight: 50,
        }}
      >
        <img src={logo} height="30" alt="pabhaLogo" />
      </div>
    </div>
  );
}

export default SiteHeader;
