import { React } from "react";

function PaintingComponent(props) {

  let heightPerLine = 25;

  if (props.painting.title) {
    return (
      <div
        style={{
          margin: 25,
          width: 250,
          fontSize: 14,
          textAlign: "center",
          height: 350,
        }}
      >
        <div
          style={{
            height: 250,
            width: 250,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={`/paintings/${props.painting.title}.jpg`}
            alt={props.painting.title}
            style={{
              maxHeight: 250,
              maxWidth: 250,
              objectFit: "contain",
            }}
          />
        </div>
        <div
          style={{
            width: 250,
            height: 150,
            fontSize: 16,
            textAlign: "center",
          }}
        >
          {props.painting.title ? (
            <div
              style={{
                height: heightPerLine,
              }}
            >
              {props.painting.title}
            </div>
          ) : (
            <div />
          )}

          {props.painting.year ? (
            <div
              style={{
                height: heightPerLine,
              }}
            >
              {props.painting.year}
            </div>
          ) : (
            <div />
          )}

          {props.painting.width && props.painting.height ? (
            <div
              style={{
                height: heightPerLine,
              }}
            >
              {props.painting.width} x {props.painting.height} cm
            </div>
          ) : (
            <div />
          )}

          {props.painting.medium && props.painting.surface ? (
            <div
              style={{
                height: heightPerLine,
              }}
            >
              {props.painting.medium} on {props.painting.surface}{" "}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    );
  } else return <div></div>;
}

export default PaintingComponent;
