import { React, useState, useEffect } from "react";
import SiteLeftBar from "./SiteLeftBar";
import SiteMainContent from "./SiteMainContent";
import PaintingInformation from "../PaintingInformation/information.json";

function SiteBody(props) {
  const [searchTitle, setSearchTitle] = useState("");
  // Array, [0] start year, [1] end year
  const [dateCategory, setDateCategory] = useState([0, 9999]);
  const [mediumCategory, setMediumCategory] = useState({});
  const [sizeCategoy, setSizeCategoy] = useState([
    0,
    200,
    false,
    0,
    200,
    false,
  ]);

  const [paintingList, setPaintingList] = useState([]);

  const [leftBarHideStatus, setLeftBarHideStatus] = useState(false);

  useEffect(() => {
    console.log(sizeCategoy);
  }, [sizeCategoy]);

  useEffect(() => {
    let list1 = [];

    //filter by title
    PaintingInformation.forEach((element) => {
      if (searchTitle && searchTitle.length > 1 && element.title.length > 1) {
        let paintingTitle = element.title.toLowerCase();
        if (paintingTitle.indexOf(searchTitle.toLowerCase()) !== -1) {
          list1.push(element);
        }
      } else {
        list1.push(element);
      }
    });

    let list2 = [];
    // filter by date
    list1.forEach((element) => {
      if (dateCategory !== undefined && element.year) {
        let startYear =
          dateCategory[0] !== undefined ? parseInt(dateCategory[0]) : 0;
        let endYear =
          dateCategory[1] !== undefined ? parseInt(dateCategory[1]) : 9999;
        let paintingYear = element.year ? parseInt(element.year) : -1;
        if (paintingYear >= startYear && paintingYear <= endYear) {
          list2.push(element);
        }
      }
    });

    //filter by medium
    let list3 = [];
    if (Object.keys(mediumCategory).length >= 1) {
      list2.forEach((painting) => {
        for (const [key] of Object.entries(mediumCategory)) {
          //painting.medium === key
          if ((painting.medium.toLowerCase()).indexOf(key.toLowerCase()) !== -1) {
            list3.push(painting);
          }
        }
      });
    } else {
      list3 = list2;
    }

    //filter by size
    let list4 = [];
    if (sizeCategoy[2] === true || sizeCategoy[5] === true) {
      list3.forEach((painting) => {
        // only true if both width and height checks pass or if not checked due to not selected
        let passSize = true;

        // dont set true, only set fail if painting fails
        // reject those with missing information
        if (painting.width || painting.height) {
        } else {
          passSize = false;
        }
        if (sizeCategoy[2] === true) {
          let paintingWidth = painting.width ? +painting.width : -1;
          if (
            paintingWidth >= sizeCategoy[0] &&
            paintingWidth <= sizeCategoy[1]
          ) {
            // passSize = true;
          } else {
            passSize = false;
          }
        }
        if (sizeCategoy[5] === true) {
          let paintingHeight = painting.height ? +painting.height : -1;
          if (
            paintingHeight >= sizeCategoy[3] &&
            paintingHeight <= sizeCategoy[4]
          ) {
            // passSize = true;
          } else {
            passSize = false;
          }
        }
        if (passSize) {
          list4.push(painting);
        }
      });
    } else {
      // if size options are not selected then skip checks
      list4 = list3;
    }

    list4.sort((a, b) => {
      return b.width - a.width;
    });
    setPaintingList(list4);
  }, [searchTitle, dateCategory, mediumCategory, sizeCategoy]);

  return (
    <div
      style={{
        height: 100,
        textAlign: "start",
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <SiteLeftBar
        leftBarHideStatus={leftBarHideStatus}
        setLeftBarHideStatus={setLeftBarHideStatus}
        dateCategoryHandler={setDateCategory}
        mediumCategoryHandler={setMediumCategory}
        searchTitleHandler={setSearchTitle}
        sizeCategoyHandler={setSizeCategoy}
      />

      <div
        style={{
          paddingLeft: leftBarHideStatus ? 50 : 250,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <SiteMainContent paintingList={paintingList} />
      </div>
    </div>
  );
}

export default SiteBody;
