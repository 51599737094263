import { React, useEffect, useState } from "react";
import PaintingComponent from "./PaintingComponent";
import { InputNumber, Select } from "antd";

function SiteMainContent(props) {
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [userInputPageNumber, setUserInputPageNumber] = useState(1);
  const [numItemsPerPage, setNumItemsPerPage] = useState(15);
  const [pageArray, setPageArray] = useState([1]);

  const { Option } = Select;

  const numItemsPerPageArray = [10, 15, 20, 25, 50, 75, 100];

  useEffect(() => {
    // reset when painting list changes and when selected numItemsPerPage changes
    setCurrentPageNumber(1);
    let numPages = Math.floor(props.paintingList.length / numItemsPerPage) + 1;
    let newPageArray = [];
    for (var i = 1; i <= numPages; i++) {
      newPageArray.push(i);
    }
    setPageArray(newPageArray);
    window.scrollTo(0, 0);
  }, [props.paintingList, numItemsPerPage]);

  let jumpToPage = (pageNumber) => {
    setCurrentPageNumber(pageNumber);
    setUserInputPageNumber(pageNumber);
    window.scrollTo(0, 0);
  };

  let pageJumpButtonHandler = () => {
    jumpToPage(userInputPageNumber);
  };

  if (props.paintingList && props.paintingList.length > 0) {
    return (
      <div>
        {
          // main painting images body
        }
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          {props.paintingList.map((painting, index) => {
            if (
              index >= 0 + numItemsPerPage * (currentPageNumber - 1) &&
              index < numItemsPerPage * currentPageNumber
            ) {
              return <PaintingComponent key={index} painting={painting} />;
            } else {
              return <div />;
            }
          })}
        </div>

        {
          // pagination component
        }
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            fontSize: 16,
          }}
        >
          {
            // page number segment start
          }
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 16,
            }}
          >
            <div style={{ minWidth: 50, marginRight: 10 }}>{"Page : "}</div>
            <div
              style={{
                marginRight: 10,
                fontSize: 18,
              }}
              onClick={() => {
                jumpToPage(currentPageNumber - 1);
              }}
            >
              {currentPageNumber > 1 ? "<" : ""}
            </div>

            {pageArray.map((pageNumber, index) => {
              if (pageNumber === 1 && currentPageNumber > 3) {
                // handles first item
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 10,
                    }}
                  >
                    <div
                      style={{
                        marginRight: 10,
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        jumpToPage(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </div>
                    <div
                      style={{
                        marginRight: 10,
                      }}
                    >
                      ...
                    </div>
                  </div>
                );
              } else if (
                pageNumber <= currentPageNumber + 3 &&
                pageNumber >= currentPageNumber - 3
              ) {
                // handles middle items
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 10,
                    }}
                  >
                    <div
                      style={{
                        marginRight: 10,
                        textDecoration: "underline",
                        color:
                          pageNumber === currentPageNumber ? "blue" : "black",
                      }}
                      onClick={() => {
                        jumpToPage(pageNumber);
                      }}
                    >
                      {pageNumber}
                    </div>
                    <div>{pageNumber !== pageArray.length ? " , " : ""}</div>
                  </div>
                );
              } else if (
                pageNumber === pageArray.length &&
                currentPageNumber < pageArray.length - 3
              ) {
                // handles middle items
                return (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: 10,
                    }}
                  >
                    <div
                      style={{
                        marginRight: 10,
                      }}
                    >
                      ...
                    </div>
                    <div
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        jumpToPage(pageNumber);
                      }}
                    >
                      {+pageNumber}
                    </div>
                  </div>
                );
              } else {
                return <div></div>;
              }
            })}
            <div
              style={{
                marginRight: 10,
                fontSize: 18,
              }}
              onClick={() => {
                jumpToPage(currentPageNumber + 1);
              }}
            >
              {currentPageNumber < pageArray.length ? ">" : ""}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginLeft: 10,
              }}
            >
              <button
                onClick={() => {
                  pageJumpButtonHandler();
                }}
              >
                Go to:
              </button>
              <InputNumber
                value={userInputPageNumber}
                style={{ width: 60, height: 32 }}
                onChange={(value) => {
                  setUserInputPageNumber(value);
                }}
                onStep={(value) => {
                  setUserInputPageNumber(value);
                }}
                onPressEnter={(e) => {
                  setUserInputPageNumber(e.target.value);
                  pageJumpButtonHandler();
                }}
              ></InputNumber>
            </div>
          </div>
          {
            // page number segment end
          }
          {
            // num items segment start
          }
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: 10,
              }}
            >
              Number of items per page:
            </div>
            <Select
              defaultValue={15}
              value={numItemsPerPage}
              style={{ width: 75 }}
              onChange={(value) => {
                setNumItemsPerPage(value);
              }}
            >
              {numItemsPerPageArray.map((val) => {
                return <Option value={val}>{val}</Option>;
              })}

            </Select>
          </div>
          {
            // num items segment end
          }
        </div>
      </div>
    );
  } else
    return (
      <div style={{ margin: 50, fontSize: 30 }}>
        No Paintings in selected category
      </div>
    );
}

export default SiteMainContent;
