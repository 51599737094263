import { React, useEffect, useState } from "react";
import {
  Button,
  Divider,
  Input,
  Checkbox,
  InputNumber,
  Slider,
  Switch,
} from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

function SiteLeftBar(props) {
  const [searchTitle, setSearchTitle] = useState("");

  const [startYear, setStartYear] = useState(1955);
  const [endYear, setEndYear] = useState(2055);

  const [mediumObject, setMediumObject] = useState({});

  const [minWidthCategory, setMinWidthCategory] = useState(0);
  const [maxWidthCategory, setMaxWidthCategory] = useState(200);
  const [useWidthCategory, setUseWidthCategory] = useState(false);

  const [minHeightCategory, setMinHeightCategory] = useState(0);
  const [maxHeightCategory, setMaxHeightCategory] = useState(200);
  const [useHeightCategory, setUseHeightCategory] = useState(false);

  let mediumCategoryHandler = () => {};
  if (props.mediumCategoryHandler !== undefined) {
    mediumCategoryHandler = props.mediumCategoryHandler;
  }

  let sizeCategoyHandler = () => {};
  if (props.sizeCategoyHandler !== undefined) {
    sizeCategoyHandler = () => {
      props.sizeCategoyHandler([
        minWidthCategory,
        maxWidthCategory,
        useWidthCategory,
        minHeightCategory,
        maxHeightCategory,
        useHeightCategory,
      ]);
    };
  }

  useEffect(() => {
    sizeCategoyHandler();
  }, [useWidthCategory, useHeightCategory]);

  useEffect(() => {
    mediumCategoryHandler({ ...mediumObject });
  }, [mediumObject]);

  if (
    props.dateCategoryHandler !== undefined &&
    props.leftBarHideStatus !== undefined &&
    props.setLeftBarHideStatus !== undefined
  ) {
    // set start date only

    let handleDateReset = () => {
      setStartYear(1955);
      setEndYear(2055);
      props.dateCategoryHandler([1955, 2055]);
    };

    let setDateRange = () => {
      props.dateCategoryHandler([startYear, endYear]);
    };

    let handleSearchTitleReset = () => {
      setSearchTitle("");
      props.searchTitleHandler("");
    };

    let handleSizeReset = () => {
      setMinWidthCategory(0);
      setMaxWidthCategory(200);
      setUseWidthCategory(false);
      setMinHeightCategory(0);
      setMaxHeightCategory(200);
      setUseHeightCategory(false);
    };

    let setTitleSearch = () => {
      props.searchTitleHandler(searchTitle);
    };

    if (props.leftBarHideStatus) {
      return (
        <div
          style={{
            position: "fixed",
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            maxWidth: 50,
            paddingLeft: 30,
            alignItems: "flex-start",
            fontSize: 18,
          }}
        >
          <div style={{ height: 40 }}></div>
          <MenuUnfoldOutlined
            tooltiptext="Unhide Sidebar"
            onClick={() => {
              props.setLeftBarHideStatus(false);
            }}
          />
        </div>
      );
    } else
      return (
        <div
          style={{
            position: "fixed",
            textAlign: "start",
            display: "flex",
            flexDirection: "column",
            minWidth: 250,
            width: 250,
            fontSize: 18,
          }}
        >
          <div
            style={{
              paddingLeft: 30,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div style={{ height: 40 }}></div>
            <MenuFoldOutlined
              onClick={() => {
                props.setLeftBarHideStatus(true);
              }}
            />

            {
              // Title Filter Start
            }
            <Divider orientation="left">Filter By Painting Title</Divider>
            <div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                <Input
                  value={searchTitle}
                  style={{ width: 150 }}
                  placeholder="Painting Title"
                  onChange={(e) => {
                    setSearchTitle(e.target.value);
                  }}
                  onPressEnter={() => {
                    setTitleSearch();
                  }}
                />
              </div>
              <Button
                type="primary"
                onClick={() => {
                  setTitleSearch();
                }}
                style={{ width: 150 }}
              >
                Filter By Title
              </Button>
            </div>

            {
              // Year Filter Start
            }
            <Divider orientation="left">Filter By Year Range</Divider>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 20,
              }}
            >
              <div>
                <InputNumber
                  value={startYear}
                  style={{ width: 70 }}
                  placeholder="1955"
                  onChange={(value) => {
                    setStartYear(value);
                  }}
                  onStep={(value) => {
                    setStartYear(value);
                  }}
                  onPressEnter={(e) => {
                    setStartYear(e.target.value);
                    setDateRange();
                  }}
                />
              </div>
              <p
                style={{
                  margin: 0,
                  height: 30,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                to
              </p>
              <div>
                <InputNumber
                  value={endYear}
                  style={{ width: 70 }}
                  placeholder="2020"
                  onChange={(value) => {
                    setEndYear(value);
                  }}
                  onStep={(value) => {
                    setEndYear(value);
                  }}
                  onPressEnter={(e) => {
                    setEndYear(e.target.value);
                    setDateRange();
                  }}
                />
              </div>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => {
                  setDateRange();
                }}
                style={{ width: 150 }}
              >
                Filter By Date
              </Button>
            </div>

            {
              // Year Filter End
            }
            <Divider orientation="left">Filter By Medium</Divider>
            {
              // Medium Filter Start
            }
            <div
              style={{
                margin: 0,
                textAlign: "start",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Checkbox
                style={{ marginLeft: 8 }}
                checked={mediumObject.Acrylic}
                onChange={(e) => {
                  if (e.target.checked) {
                    let newObject = { ...mediumObject, Acrylic: "Acrylic" };
                    setMediumObject({ ...newObject });
                  } else {
                    let newObject = { ...mediumObject };
                    delete newObject.Acrylic;
                    setMediumObject({ ...newObject });
                  }
                }}
              >
                Acrylic
              </Checkbox>

              <Checkbox
                checked={mediumObject.Charcoal}
                value="Charcoal"
                onChange={(e) => {
                  if (e.target.checked) {
                    let newObject = { ...mediumObject, Charcoal: "Charcoal" };
                    setMediumObject({ ...newObject });
                  } else {
                    let newObject = { ...mediumObject };
                    delete newObject.Charcoal;
                    setMediumObject({ ...newObject });
                  }
                }}
              >
                Charcoal
              </Checkbox>

              <Checkbox
                checked={mediumObject.Oil}
                value="Oil"
                onChange={(e) => {
                  if (e.target.checked) {
                    let newObject = { ...mediumObject, Oil: "Oil" };
                    setMediumObject({ ...newObject });
                  } else {
                    let newObject = { ...mediumObject };
                    delete newObject.Oil;
                    setMediumObject({ ...newObject });
                  }
                }}
              >
                Oil
              </Checkbox>

              <Checkbox
                checked={mediumObject.Pencil}
                value="Pencil"
                onChange={(e) => {
                  if (e.target.checked) {
                    let newObject = { ...mediumObject, Pencil: "Pencil" };
                    setMediumObject({ ...newObject });
                  } else {
                    let newObject = { ...mediumObject };
                    delete newObject.Pencil;
                    setMediumObject({ ...newObject });
                  }
                }}
              >
                Pencil
              </Checkbox>

              <Checkbox
                checked={mediumObject.Watercolour}
                value="Watercolour"
                onChange={(e) => {
                  if (e.target.checked) {
                    let newObject = {
                      ...mediumObject,
                      Watercolour: "Watercolour",
                    };
                    setMediumObject({ ...newObject });
                  } else {
                    let newObject = { ...mediumObject };
                    delete newObject.Watercolour;
                    setMediumObject({ ...newObject });
                  }
                }}
              >
                Watercolour
              </Checkbox>
            </div>
            {
              // Medium Filter End
            }
            <Divider orientation="left"> Filter By Size </Divider>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                fontSize: 14,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Width (cm)</div>
              <Switch
                checked={useWidthCategory}
                onClick={(e) => {
                  setUseWidthCategory(e);
                }}
                size="small"
              ></Switch>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                fontSize: 14,
                alignItems: "center",
              }}
            >
              <div style={{ width: 12 }}>{minWidthCategory}</div>
              <Slider
                range
                max={300}
                value={[minWidthCategory, maxWidthCategory]}
                defaultValue={[0, 200]}
                onChange={(e) => {
                  if (e[0] !== undefined && e[1] !== undefined) {
                    setMinWidthCategory(e[0]);
                    setMaxWidthCategory(e[1]);
                  }
                }}
                onAfterChange={() => {
                  sizeCategoyHandler();
                }}
                style={{
                  width: 120,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
              <div>{maxWidthCategory}</div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                fontSize: 14,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Height (cm)</div>
              <Switch
                checked={useHeightCategory}
                onClick={(e) => {
                  setUseHeightCategory(e);
                }}
                size="small"
              ></Switch>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                width: "100%",
                fontSize: 14,
                alignItems: "center",
              }}
            >
              <div style={{ width: 12 }}>{minHeightCategory}</div>
              <Slider
                range
                max={300}
                value={[minHeightCategory, maxHeightCategory]}
                defaultValue={[0, 200]}
                onChange={(e) => {
                  if (e[0] !== undefined && e[1] !== undefined) {
                    setMinHeightCategory(e[0]);
                    setMaxHeightCategory(e[1]);
                  }
                }}
                onAfterChange={() => {
                  sizeCategoyHandler();
                }}
                style={{
                  width: 120,
                  marginLeft: 10,
                  marginRight: 10,
                }}
              />
              <div>{maxHeightCategory}</div>
            </div>
            <Divider />
            <div />
            <p />
            <div>
              <Button
                type="primary"
                onClick={() => {
                  //reset title
                  handleSearchTitleReset();
                  //reset date
                  handleDateReset();
                  //reset medium
                  setMediumObject({});
                  //reset size
                  handleSizeReset({});
                }}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
      );
  } else {
    return <div> Error in Site Left Bar</div>;
  }
}

export default SiteLeftBar;
